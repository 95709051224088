import React, { FormEvent, useContext, useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';

import { useAnalytics } from '../../analytics';
import { AnalyzeContext } from '../../context/analyze-context';
import { AppContext } from '../../context/app-context';
import { AuthorizationContext } from '../../context/authorization-context';
import { MonitorContext } from '../../context/monitor-context';
import { SavedViewContext } from '../../context/saved-view-context';
import { useMutateSavedViews } from '../../hooks/accounts';
import { useAlertsAPI } from '../../hooks/alerts';
import { useBuildSavedView, useGetDefaultView } from '../../hooks/view';
import { truncate } from '../../utils/text';
import AlertModal from '../alert-modal/alert-modal';
import { TimeRangeDropdown } from '../timeframe-modal/time-range-selector';

import { CustomViewDeleteConfirmContent, FooterInfo } from './custom-view-delete-confirm-modal';
import { StyledCustomViewInput } from './styles';

import {
  Button,
  Li,
  Modal,
  ModalContents,
  ModalOpenButton,
  OptionsGroup,
  TextInput,
  Ul,
} from '@controlrooms/components';
import { useClickOutside } from '@controlrooms/hooks';
import { AnalyzeSavedView, MonitorSavedView, ViewType } from '@controlrooms/models';

export const CustomViewManager: React.FC<{
  title: string;
  views?: (MonitorSavedView | AnalyzeSavedView)[];
  viewType?: string;
  onTitleChange?: (param: string) => void;
}> = ({ views, title, viewType, onTitleChange }) => {
  const getDefaultView = useGetDefaultView();
  const queryClient = useQueryClient();
  const defaultView = getDefaultView(viewType);
  const { saveView, deleteView } = useMutateSavedViews();
  const { canUserReadAlert } = useContext(AuthorizationContext);
  const [isTimePresetsOpen, setIsTimePresetsOpen] = useState(false);
  const [isViewSelectOpen, setIsViewSelectOpen] = useState(false);
  const [timeSelection, setTimeSelection] = useState(null);
  const [viewName, setViewName] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [isMenuSelectOpen, setIsMenuSelectOpen] = useState(false);
  const [loadingSaveView, setLoadingSaveView] = useState<boolean>(false);
  const [fieldError, setFieldError] = useState('');
  const { isCustomViewFormVisible, setIsCustomViewFormVisible } = useContext(AppContext);
  const { deleteAlertByCustomViewMutation } = useAlertsAPI();
  const { mutateAsync: deleteAlertByCustomViewId } = deleteAlertByCustomViewMutation;

  const { currentView, setCurrentView } = useContext(SavedViewContext);
  const buildView = useBuildSavedView();

  const { updateView: updateMonitorView } = useContext(MonitorContext);
  const { updateView: updateAnalyzeView } = useContext(AnalyzeContext);

  const { setSelectedViewTitle } = useContext(MonitorContext);

  const ulRef = useRef(null);
  const { track } = useAnalytics();
  useClickOutside(ulRef, () => setIsViewSelectOpen(false));

  const menuUlRef = useRef(null);
  useClickOutside(menuUlRef, () => setIsMenuSelectOpen(false));

  const handleMenuClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setIsMenuSelectOpen(!isMenuSelectOpen);
  };

  const handleTitleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!views?.length) {
      return;
    }
    e.stopPropagation();
    setIsViewSelectOpen(!isViewSelectOpen);
  };

  const deleteAlertByCustomView = async (targetId: string, targetType: string) => {
    try {
      await deleteAlertByCustomViewId({ targetId, targetType });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error('No Alerts/Rules for this Custom View', error);
    }
  };

  const deleteCustomView = () => {
    track('Delete Custom View', {
      deleteCustomView: 'clicked',
      deletingCustomView: currentView?.name || '',
    });
    if (!currentView || currentView === defaultView) return true;
    deleteView(currentView).then(() => {
      selectCustomView(defaultView);
      if (currentView?.id) {
        deleteAlertByCustomView(currentView.id, 'view');
      }
    });
    return true;
  };

  const editCustomView = () => {
    setViewName(currentView?.name || '');
    setIsEditMode(true);
    setIsCustomViewFormVisible(true);
  };

  useEffect(() => {
    if (isEditMode && isCustomViewFormVisible) {
      track('Edit Custom View', {
        editCustomView: 'clicked',
        editingCustomView: currentView?.name || '',
      });
    }
    // eslint-disable-next-line
  }, [isEditMode, isCustomViewFormVisible]);

  const selectCustomView = (view: MonitorSavedView | AnalyzeSavedView) => {
    if (onTitleChange) onTitleChange(view.name);
    setCurrentView(view);
    track('Select Custom View', {
      selectCustomView: 'clicked',
      selectedCustomViewProperties: { ...view },
    });
    view.type === ViewType.MONITOR
      ? updateMonitorView(view as MonitorSavedView)
      : updateAnalyzeView(view as AnalyzeSavedView);
    // setCurrentTimeStamp();
    setIsViewSelectOpen(false);
  };

  const onSavingCustomView = () => {
    if (!viewName || viewName === '') {
      setFieldError('Custom View Name cannot be empty!');
    } else if (views?.find((view) => view.name === viewName)) {
      setFieldError('View name already exist!');
    } else saveCustomView();
  };

  const updateViewNameInList = (view: MonitorSavedView | AnalyzeSavedView) => {
    // update the name of the view in the list using the view id
    views?.map((v) => (v.id === view.id ? { ...v, name: view.name } : v));
    queryClient.invalidateQueries('user-views');
    queryClient.resetQueries({ queryKey: ['user-views'] });
    setSelectedViewTitle(view.name);
  };

  const saveCustomView = () => {
    if (loadingSaveView) return;

    setLoadingSaveView(true);
    const view = buildView(viewName, timeSelection);
    if (isEditMode && currentView) {
      view.name = viewName;
      view.id = currentView.id;
    }

    const prevView = currentView && { ...currentView };
    selectCustomView(view);
    saveView(view, isEditMode)
      .then(() => {
        track('Save Custom View', {
          saveCustomView: 'clicked',
          savedCustomViewProperties: { ...view },
        });
        setIsCustomViewFormVisible(false);
        setViewName('');
        setIsEditMode(false);
        setLoadingSaveView(false);
      })
      .catch(() => {
        setLoadingSaveView(false);
        prevView && selectCustomView(prevView as MonitorSavedView | AnalyzeSavedView);
      })
      .finally(() => {
        setFieldError('');
        setLoadingSaveView(false);
        updateViewNameInList(view);
      });
  };

  // Reset on navigation
  useEffect(() => {
    return function cleanup() {
      setViewName('');
      setFieldError('');
      setIsCustomViewFormVisible(false);
    };
  }, [setIsCustomViewFormVisible]);

  return (
    <StyledCustomViewInput>
      {isCustomViewFormVisible ? (
        <div className="edit-view-mode">
          <div className="cv-buttons">
            <TextInput
              data-testid="custom-view-input"
              placeholder="Custom View Name"
              value={viewName}
              errorMessage={fieldError}
              errorId="custom_view_creation_error"
              onChange={(e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                const target: HTMLInputElement = e.currentTarget as HTMLInputElement;
                setFieldError('');
                setViewName(target.value);
                track('Change Custom View Name', {
                  customViewName: target.value,
                });
              }}
              onKeyDown={(e: React.KeyboardEvent) => {
                e.stopPropagation();
              }}
            />
            <Button
              buttonSize="small"
              buttonType="text"
              data-testid="cancel-custom-view"
              onClick={() => {
                setIsCustomViewFormVisible(false);
                setViewName('');
                setFieldError('');
                setIsEditMode(false);
                track('Close Custom View', {
                  closeCustomView: 'clicked',
                });
              }}
            >
              Cancel
            </Button>
            <Button
              data-testid="save-custom-view"
              buttonSize="small"
              buttonType="text"
              iconName="download"
              onClick={onSavingCustomView}
            >
              {loadingSaveView ? 'Saving...' : 'Save'}
            </Button>
          </div>
          <div data-testid="select-time-custom-view">
            <TimeRangeDropdown
              value={timeSelection || currentView?.timeSelection.timePreset || ''}
              isSelectOpen={isTimePresetsOpen}
              setIsSelectOpen={setIsTimePresetsOpen}
              onSelect={(v) => setTimeSelection(v)}
              dataTestId="custom-view"
            />
          </div>
        </div>
      ) : (
        <>
          <OptionsGroup className={`view-select ${views && 'has-views'}`}>
            <Button
              data-testid="system-view"
              buttonSize="large"
              buttonType={views?.length ? 'menu-text' : 'text'}
              className="view-title"
              onClick={(e) => handleTitleClick(e)}
              aria-haspopup="listbox"
              aria-expanded={isViewSelectOpen}
            >
              {truncate(currentView?.name || title, 30)}
            </Button>
            <Ul
              isOpen={isViewSelectOpen}
              className="view-dropdown"
              ref={ulRef}
              data-testid="custom-view-list"
            >
              <Li
                className="view-menu-item"
                onClick={() => selectCustomView(getDefaultView(viewType))}
                key={`${defaultView.name}-${defaultView.timeSelection.startTime}`}
                data-testid={`custom_view_list_${defaultView.name}`}
              >
                {defaultView.name}
              </Li>
              {views?.map((view) => (
                <Li
                  className="view-menu-item"
                  onClick={() => selectCustomView(view)}
                  key={`${view.name}-${view.timeSelection.startTime}`}
                  data-testid={`custom_view_list_${view.name}`}
                  title={view.name}
                >
                  {truncate(view.name || '', 30)}
                </Li>
              ))}
            </Ul>
          </OptionsGroup>
          {currentView && currentView !== defaultView && (
            <>
              <OptionsGroup className="menu-select">
                <Button
                  buttonType="icon"
                  buttonSize="small"
                  iconName="menu"
                  data-testid="custom_view_menu"
                  className="no-border"
                  onClick={(e) => handleMenuClick(e)}
                  aria-haspopup="listbox"
                  aria-expanded={isMenuSelectOpen}
                />
                <Ul isOpen={isMenuSelectOpen} className="sort-dropdown" ref={menuUlRef}>
                  {canUserReadAlert && (
                    <Li data-testid={`manage-alert-${currentView.name}`} key="manage-alert">
                      <AlertModal targetType="view" targetId={currentView?.id}>
                        <div>Manage Alerts</div>
                      </AlertModal>
                    </Li>
                  )}
                  <Li data-testid={`custom_view_edit`} key="edit-alert">
                    <div onClick={editCustomView}>Edit custom view</div>
                  </Li>
                  <Li data-testid={`delete-custom-view`} key="delete-alert">
                    <Modal unmountOnExit>
                      <ModalOpenButton>
                        <div>Delete custom view</div>
                      </ModalOpenButton>
                      <ModalContents
                        title="Are you sure you want to delete this saved view?"
                        styles={{ content: { maxWidth: '480px' } }}
                        confirmButtonText="Delete Saved View"
                        confirmButtonCallback={deleteCustomView}
                        footerPrompt={<FooterInfo />}
                      >
                        <CustomViewDeleteConfirmContent
                          {...currentView}
                        ></CustomViewDeleteConfirmContent>
                      </ModalContents>
                    </Modal>
                  </Li>
                  {/* <Li data-testid="more-actions" key="more-actions">
                    <span>More Actions</span>
                  </Li> */}
                </Ul>
              </OptionsGroup>
            </>
          )}
        </>
      )}
    </StyledCustomViewInput>
  );
};
