import styled, { css } from 'styled-components';

import { breakpoints } from '../../global-styles';

export const StyledCollapsible = styled.div`
  box-shadow: ${({ theme }) => theme.sidebar.headers.gridRowInnerShadow};
  > .collapsible-bar {
    display: flex;
    flex: 1;
    justify-content: space-between;
    background: transparent;
    border: 0;
    color: currentColor;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 0;
    transition: height 0.2s ease-in-out;
    cursor: pointer;

    > .title {
      font-weight: 700;
      font-size: 11px;
      font-family: 'Open Sans', sans-serif;
      line-height: 17px;
      padding: 2px 28px 2px 38px;
    }
    > .arrow-icon {
      width: 22px;
      height: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: rotate(90deg);
      transition: transform 0.2s linear;
      > div > svg {
        fill: ${({ theme }) => theme.sidebar.folders.accordionArrowExpanded};
      }
    }
  }
`;

export const CollapsibleBar = styled.button<{ open: boolean }>`
  display: flex;
  background: ${({ theme }) => theme.sidebar.headers.backgroundColor};
  align-items: center;
  border: 0;
  color: currentColor;
  margin: 0;
  padding: 0;
  width: 100%;
  transition: height 0.2s ease-in-out;
  cursor: pointer;

  &:hover,
  &.selected {
    .sidebar-collapse {
      background: ${({ theme }) => theme.sidebar.folders.hover.backgroundGradient};

      .header-checkbox {
        visibility: initial;
        > div {
          border-color: ${({ theme }) => theme.sidebar.folders.hover.checkboxBorderColor};
        }
      }

      .drag-handle {
        // visibility: initial;
        cursor: grab;
      }
    }

    .heatmap-collapse {
      background: ${({ theme }) => theme.sidebar.folders.hover.backgroundGradient};
    }
  }

  .sidebar-collapse {
    max-width: 310px;
    display: flex;
    flex: 1;
    align-items: center;
    padding: 0 4px 0 6px;
    color: ${({ theme }) => theme.sidebar.folders.headerTextColor};

    > .title {
      font-size: 11px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 700;
      line-height: 17px;
      padding: 2px 0;

      ${breakpoints.xl} {
        font-size: 17px;
        line-height: 19px;
        padding: 4px 0;
      }
    }

    > .description {
      flex-grow: 1;
      text-align: left;
      margin-right: 22px;
      font-size: 11px;
      font-family: 'Open Sans', sans-serif;
    }

    .colapsbtn {
      display: flex;
      > .arrow-icon {
        height: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.5rem;
        transform: rotate(0deg);
        transition: transform 0.2s linear;
        > div > svg {
          fill: transparent;
          stroke: ${({ theme }) => theme.sidebar.folders.accordionArrowExpanded};
        }
      }
      ${({ open }) =>
        open &&
        css`
           {
            > .arrow-icon {
              transform: rotate(180deg);
              transition: transform 0.2s linear;
              > div > svg {
                stroke: transparent;
                fill: ${({ theme }) => theme.sidebar.folders.accordionArrowExpanded};
              }
            }
          }
        `}
    }

    .drag-handle,
    .header-checkbox {
      margin-right: 5px;
      visibility: hidden;
    }
  }
  .heatmap-collapse {
    flex: 1;
    background: ${({ theme }) => theme.sidebar.headers.heatmap.backgroundColor};
    box-shadow: ${({ theme }) => theme.sidebar.headers.heatmap.gridRowInnerShadow};
    height: 21px;
    &:hover {
      ${({ theme }) => theme.sidebar.headers.gridRowHeaderHoverColor};
    }
    ${breakpoints.xl} {
      height: 28px;
    }
  }
`;

export const CollapsibleContent = styled.div<{ open: boolean }>`
  overflow: hidden;
  transition: height 0.3s ease-in-out;
`;
