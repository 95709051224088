import classNames from 'classnames';
import { useContext, useMemo } from 'react';
import Highlighter from 'react-highlight-words';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';

import { useAnalytics } from '../../analytics';
import { AnalyzeContext } from '../../context/analyze-context';
import { SearchContext } from '../../context/search-context';
import { FolderSort, useFilteredFolders, usePlantFolders } from '../../hooks/folders';
import { SidebarFooter } from '../../pages/monitor/styles';

import { ExpandIcon } from './styles/styles';
import {
  SubSystemContainer,
  SubSystemViewContainer,
  SystemSectionContainer,
} from './styles/system-styles';
import { SubSystemTagView, SystemAndTagHeaderContent, TagComponent } from './tag-view';

import { Checkbox, Icon, StyledCommonTooltip, Tooltip } from '@controlrooms/components';
import { Checkable, Navigable, ParentTag, SubSystem, System, Tag } from '@controlrooms/models';
import { sanitizeString } from '@controlrooms/utils';

interface FlattenedItem {
  type: 'root' | 'folder' | 'tag';
  item: System<SubSystem> | SubSystem | Tag;
}

// Helper function to flatten the folder structure
const flattenFolders = (folder: SubSystem): FlattenedItem[] => {
  const result: FlattenedItem[] = [];

  // If it's a folder (SubSystem with tags)
  result.push({ type: 'folder', item: folder });

  // Add all the tags under the folder
  folder.tags.forEach((tag: Tag) => {
    result.push({ type: 'tag', item: { ...tag, folder: folder.folder, folderName: folder.name } });
  });

  // Recursively process subfolders
  folder.subfolders.forEach((subfolder) => {
    result.push(...flattenFolders(subfolder));
  });

  return result;
};

const flattenSystemFolders = (system: System<SubSystem>): FlattenedItem[] => {
  const result: FlattenedItem[] = [];

  // Add the system as a root folder
  result.push({ type: 'root', item: system });

  // Process each subfolder
  system.subfolders.forEach((subfolder) => {
    result.push(...flattenFolders(subfolder)); // Subfolders contain tags and deeper subfolders
  });

  return result;
};

interface SearchViewProps {
  handleCheckboxCheck: (id: number, tag?: ParentTag) => void;
  pinnedTags?: ParentTag[];
  onNav: (folderId: number) => void;
}

export const SearchView: React.FC<SearchViewProps> = ({
  handleCheckboxCheck,
  pinnedTags,
  onNav,
}) => {
  const { searchTerm } = useContext(SearchContext);
  const { selectedFolders } = useContext(AnalyzeContext);
  const { data: plant } = usePlantFolders();
  const handleNav = (id: number) => id && onNav(id);

  // Filter the folders using the custom hook
  const data = useFilteredFolders(plant?.subfolders || []);

  // Manage expanded state of folders in a separate state variable
  // const [expandedFolders, setExpandedFolders] = useState<{ [key: number]: boolean }>({});

  // Helper to toggle the expanded state of folders
  // const toggleExpand = (folderId: number) => {
  //   setExpandedFolders((prevState) => ({
  //     ...prevState,
  //     [folderId]: !prevState[folderId],
  //   }));
  // };

  // Flatten the folder structure
  const flatList = useMemo(() => {
    if (!data.length) return [];
    return data.flatMap((folder) => flattenSystemFolders(folder));
  }, [data]);

  const { track } = useAnalytics();
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const subSystemParams = search.get('SUB_SYSTEMS_SEARCH_PARAM');
  const tagNameParams = search.get('TAGS_SEARCH_PARAM');

  const removeQueryParams = () => {
    navigate({ pathname: '/analyze', search: '' });
  };

  return (
    <div className="search-view">
      <SystemSectionContainer className="expanded">
        <Virtuoso
          style={{ height: 'calc(-120px + 80vh)' }}
          className="search-container"
          totalCount={flatList.length}
          itemContent={(index) => {
            const { type, item } = flatList[index];

            // Render Root Folders (System<SubSystem>)
            if (type === 'root') {
              const system = item as System<SubSystem>;
              return (
                <div className="section-header" data-testid="section-header">
                  <Highlighter
                    searchWords={[searchTerm]}
                    textToHighlight={system.name}
                    autoEscape={true}
                  />
                  {system.description && system.name && <span>:&nbsp;</span>}
                  {system.description && (
                    <div className="description">
                      <Highlighter
                        searchWords={[searchTerm]}
                        textToHighlight={system.description}
                        autoEscape={true}
                      />
                    </div>
                  )}
                </div>
              );
            }

            // Render Folders (SubSystem)
            if (type === 'folder') {
              const folder = item as SubSystem;
              const id = item.folder || 0;
              const isAllChecked = Boolean(selectedFolders?.includes(item.folder || 0));
              const hasTags = folder.tags.length > 0;
              const hasSubfolders = !!folder.subfolders?.length;
              const subSysViewContainerClass = classNames({
                'search-result': searchTerm.length,
                expanded: true,
              });

              const subSysContainerClass = classNames({
                checked: isAllChecked,
                'no-tags': !hasTags && hasSubfolders,
              });
              // const expanded = expandedFolders[folder.folder] || false;
              return (
                <SubSystemViewContainer className={subSysViewContainerClass}>
                  <div className="header sub-system-tag-header">
                    <SubSystemContainer className={subSysContainerClass}>
                      <Checkbox
                        checked={isAllChecked}
                        className="sub-system-check"
                        onChange={() => {
                          if (subSystemParams || tagNameParams) {
                            removeQueryParams();
                          }
                          track('Analyze - SubSystem Tag View', {
                            checkedSubSystem: isAllChecked,
                            checkedSubSystemId: id,
                          });
                          handleCheckboxCheck(id);
                        }}
                      />
                      <Tooltip label={`${folder.name} / ${folder.description}`}>
                        <div
                          className="sub-system-label"
                          onClick={() => {
                            if (searchTerm.length) handleNav(id);
                          }}
                        >
                          <SystemAndTagHeaderContent
                            name={folder.name}
                            description={folder.description}
                            isHeader
                          />
                        </div>
                      </Tooltip>
                      <div
                        onClick={() => {
                          if (searchTerm.length) handleNav(id);
                        }}
                      >
                        {hasTags && !searchTerm && <ExpandIcon />}
                        <div className="nav-icon">
                          <Icon name="chevron" width="10" height="10" />
                        </div>
                      </div>
                    </SubSystemContainer>
                  </div>
                  <StyledCommonTooltip id="tag-name-description" />
                </SubSystemViewContainer>
              );
            }

            // Render Tags
            if (type === 'tag') {
              const tag = item as Tag;
              return (
                <TagComponent
                  key={tag.name}
                  folder={tag.folder || 0}
                  handleCheckboxCheck={handleCheckboxCheck}
                  pinnedTags={pinnedTags}
                  {...tag}
                />
              );
            }

            return null;
          }}
        />
      </SystemSectionContainer>
    </div>
  );
};

export const _SearchView: React.FC<Checkable & Navigable> = ({
  handleCheckboxCheck,
  pinnedTags,
  onNav,
}) => {
  const { searchTerm, setSearchInputValue, setSearchTermOnNavigate } = useContext(SearchContext);
  const { selectedFolders } = useContext(AnalyzeContext);

  const { data: plant } = usePlantFolders(FolderSort.DEFAULT);

  const data = useFilteredFolders(plant?.subfolders || []);

  const { track } = useAnalytics();

  const renderTag = (subfolder: SubSystem & { parentPath?: string }) => {
    let aggregateView: JSX.Element = <></>;
    const view = (viewSf: SubSystem & { parentPath?: string }) => (
      <SubSystemTagView
        {...viewSf}
        tags={viewSf.tags as Tag[] & ParentTag[]}
        key={sanitizeString(`${subfolder.folder}-${viewSf.name}`)}
        handleCheckboxCheck={handleCheckboxCheck}
        parentFolder={0}
        parentPath={viewSf.parentPath as string}
        expanded={true}
        pinnedTags={pinnedTags}
        selectedFolders={selectedFolders}
        onNav={onNav}
      ></SubSystemTagView>
    );

    aggregateView = view(subfolder);

    aggregateView = (
      <div key={sanitizeString(`${subfolder.folder}-${subfolder.name}`)}>
        {aggregateView}
        {subfolder.subfolders.map((sf2: SubSystem) => renderTag(sf2))}
      </div>
    );

    return aggregateView;
  };

  return (
    <div className="search-view">
      {data?.map((system) => (
        <SystemSectionContainer className="expanded" key={`${system.folder}:${system.description}`}>
          <div className="section-header" data-testid="section-header">
            <Highlighter
              searchWords={[searchTerm]}
              textToHighlight={system.name}
              autoEscape={true}
            ></Highlighter>
            {system.description && system.name && <span>:&nbsp;</span>}
            {system.description && (
              <div className="description">
                <Highlighter
                  searchWords={[searchTerm]}
                  textToHighlight={system.description}
                  autoEscape={true}
                ></Highlighter>
              </div>
            )}
          </div>
          <div className="section-content" data-testid="section-content">
            <div>
              {system.subfolders?.map((subfolder) => {
                return (
                  <div key={sanitizeString(`${subfolder.folder}-${subfolder.name}`)}>
                    {renderTag(subfolder)}
                  </div>
                );
              })}
            </div>
          </div>
        </SystemSectionContainer>
      ))}

      <SidebarFooter>
        <div className="gta-wrapper">
          <button
            className="link"
            onClick={() => {
              track('Analyze - Search', {
                clearSearch: 'clicked',
              });
              setSearchInputValue('');
              setSearchTermOnNavigate('');
            }}
          >
            <span>Close</span>
          </button>
        </div>
      </SidebarFooter>
    </div>
  );
};
