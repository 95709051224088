// TODO: add drawer animation
// TODOx2: Asked design an there is no concrete transition. Moving to conditional
// rendering to stop both sys and tag views showing at same time. Need to revisit

import styled from 'styled-components';

import { customScrollPrimary } from '../../../global-styles';

import { highlightCss, navIconCss, rowSharedCss } from './shared-styles';

import { colors, Size, text } from '@controlrooms/design-tokens';

// when transition provided.
export const TagViewContainer = styled.div`
  align-items: stretch;
  background-color: ${({ theme }) => theme.sidebar.folders.backgroundColor};
  display: flex;
  flex-flow: column nowrap;
  height: calc(100vh - 265px);
  justify-content: flex-start;
  overflow-y: auto;

  ${highlightCss}

  ${customScrollPrimary}

  &.expanded {
    .select-root {
      display: flex;
    }
  }

  .section-header-label {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    flex-grow: 1;

    &:hover {
      .name,
      .description {
        color: #ffffff;
      }
    }

    &:hover .name {
      color: ${colors.k[90]};
      font-size: 11px;
      font-weight: 700;
    }

    .description {
      color: ${colors.k[90]};
      font-size: 9px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      margin-right: 12px;
    }
  }

  .select-root {
    align-items: center;
    display: none;
    flex-flow: row nowrap;
    justify-content: flex-start;

    .sub-title {
      margin-left: 0;
    }
  }

  .sub-system-label {
    .description {
      flex-basis: 40px;
      align-self: baseline;
      margin-top: 1px;
    }
    .name {
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .sub-system-tag-header {
    .arrow-icon {
      margin-right: 3px;
    }

    ${navIconCss}
  }

  .no-tags {
    cursor: initial;
  }

  .tag-check {
    flex: 0 0 12px;
    visibility: hidden;
    width: 12px;
    margin-right: 7px;
    margin-left: 15px;
  }

  .tag-root {
    border-bottom: 1px solid ${colors.k[30]};
  }

  .tag-view-title {
    font-size: 17px;
    font-weight: 400;
    margin: 12px 22px 0;
  }

  &.search-view {
    .gta-wrapper {
      display: flex;
      flex-direction: row-reverse;
    }
  }
`;

export const TagContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.sidebar.folders.backgroundColor};
  display: -webkit-box;
  grid-template-rows: 50% 50%;
  height: 28px;
  justify-content: flex-start;
  cursor: pointer;

  &.checked {
    .name > span,
    mark {
      background-color: ${({ theme }) => theme.tagHeader.tagTitleBackgroundPinned};
    }
  }

  &:hover {
    .description {
      color: ${({ theme }) => theme.tagHeader.tagTitleColorPinned};
    }

    .name > span {
      background-color: ${({ theme }) => theme.tagHeader.tagTitleBackgroundHover};
      color: ${({ theme }) => theme.tagHeader.tagTitleColorPinned};
    }
  }

  ${rowSharedCss}

  .description {
    color: ${({ theme }) => theme.tagHeader.tagTitleColorPinned};
    font-size: ${text.size[Size.DATA_13]};
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    flex-grow: 1;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 160px;
    padding-right: 10px;
  }

  .name {
    align-items: center;
    display: flex;
    height: 28px;
    margin-right: 6px;

    & > span,
    & > mark {
      // background-color: ${({ theme }) => theme.tagHeader.tagTitleBackground};
      border-radius: 2px;
      font-size: ${text.size[Size.DATA_13]};
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      text-align: left;
    }

    & > span {
      color: ${({ theme }) => theme.tagHeader.tagTitleColorPinned};
      flex-shrink: 0;
    }
  }
  > .un-hide-tag-btn {
    background: none;
    border: none;
    padding: 15px;
    margin-left: 5px;

    svg {
      width: 15px;
    }
  }
`;
